import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import "./Success.styles.css";

export const Success = () => {
  const { t } = useTranslation();

  useEffect(() => {
    alert(t("delivered"));
  }, []);

  return <Navigate to="/career" />;
};
