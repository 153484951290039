import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        cds: "Cevher Digital Solutions",
        lang: "en",
        oplang: "tr",
        comingsoon: "Coming Soon",
        reachus: "Reach us to see",
        aboutus: "About",
        contactus: "Contact us to discover our products",
        showmore: "Show More",
        techoffice: "The Tech Office",
        aboutdescription: "We Research & Develop the Future.",
        address:
          "Dokuz Eylül University Tınaztepe Campus Doğuş Street No:207/AG DEPARK Tınaztepe Beta Building, Fifth Floor, Office, 503 Buca-İZMİR",
        phone: "Phone : ",
        headlineWhere: "Where;",
        headline:
          "Design meets industry, Today is developed, Tomorrow is designed...",
        subline: "To make a big impact.",
        home: "Home",
        products: "Products",
        opportunities: "Opportunities",
        consultancy: "Consultancy",
        about: "About",
        contact: "Contact",
        datagovernance: "Data Governance",
        dgheader:
          "By creating effective and accurate data analysis models, companies are supported in many critical processes such as growth, sales and investment. We support companies in creating the right strategies in line with the analyzes made.",
        dataanalytics: "Data Analysis",
        dataanalyticsdesc1:
          "Data management and data analysis are of great importance when it comes to making forward-oriented right decisions. With data management, it is possible to choose the decision that will provide the highest benefit to the institution.",
        dataanalyticsdesc2:
          "Data Analysis is a scientific and effective tool in the processes of making critical decisions after the collection, extraction and processing of raw data.",
        dataanalyticsdesc3:
          "The data analysis process, which consists of data access, data analysis and data visualization steps, requires the use of different programs at each step. We analyze your data by choosing the programs that are suitable for the needs decided among the data analysis programs by our data analysis experts.",
        datawarehouse: "Data Warehouse",
        datawarehousedesc1:
          "A data store is a collection of data that represents the designated target for data storage. This storage unit makes it possible to access information.",
        businessanalytics: "Business Analytics",
        baheader:
          "Job analysis is a research discipline that seeks solutions to existing problems in determining needs after the process of examining a job by breaking it into small parts.",
        datavisualization: "Data Visualization",
        datavisualizationdesc1:
          "Data analysis tool design and machine learning are essential for data visualization. The human brain processes visual information much faster. At this point, with the tool determined at this point, it can be made easier to analyze big data by making it more understandable with various graphics.",
        dataanalytictooldesign: "Data Analytic Tool Design",
        dataanalytictooldesigndesc1:
          "Data analysis tool design can be grouped under three headings: visual, numerical and verbal. While visual tools draw analysis results with the help of graphics and maps, digital tools reveal a result with numbers. Verbal analysis tools provide methods and results for identifying problems.",
        machinelearning: "Machine Learning",
        machinelearningdesc1:
          "In machine learning, a system is created by processing mathematical models as if imitating a human intelligence. Thus, it is aimed to obtain more precise results in giving forward-looking answers.",
        tailormadesoftwaresolutions: "Tailor-Made Software Solutions",
        tmheader:
          "Our talented software team can develop special software solutions according to your request and requirements so that we can solve the problems that you face.",
        tmheader2:
          "This process begins with requirement analysis and after the approval, continues with design and development.",
        tmsubtitle: "Desktop Solutions, Mobile Solutions, Web Solutions",
        tmsubtitledesc1:
          "After completing the certain decision processes, high-performance software solutions can be developed as desktop, mobile and web-based.",
        tmsubtitledesc2:
          "Our talented software team can develop special software solutions according to your request and requirements so that we can solve the problems that you face. This process begins with requirement analysis and after the approval, continues with design and development.",
        industrialprojectapplications: "Industrial Projects Applications",
        iotbasedsystems: "IoT Based Systems",
        industrialdescription1:
          "The Internet of Things, or IOT, is a technology that enables interrelated computing devices, mechanical and digital machines, in short, all objects used in daily life to exchange data with other objects and to be in full synchronization with each other.",
        industrialdescription2:
          "IoT devices share the sensor data they collect by sending the data to the cloud for analysis locally or by connecting it to another device from the cloud where it is analyzed locally. In some cases, these devices can communicate with other devices and act in accordance with the information they receive from each other. Devices do most of the work without human intervention, but can interact with devices such as setting up, giving instructions, or accessing data.",
        spleconsultancy: "SPLE Consultancy",
        domainanalysis: "Domain Analysis",
        featureextraction: "Feature Extraction",
        spledescription:
          "Domain analysis is an essential activity in software product line engineering (SPLE) to identify common and variable features among the products of a product family.",
        spledescription2:
          "Feature models are commonly used for depicting the identified commonalities and variations. However, it requires significant time and effort to perform domain analysis and to create a feature model.",
        bigdata: "Big Data and Collecting",
        bigdataheader:
          "Examples of big data sources are application data stores such as relational databases, static files such as web server log files produced by applications, and real-time data sources such as IoT devices.",
        bigdataheader2:
          "For big data solutions, the data is prepared for analysis, then the processed data is made into a structured form that can be queried using analysis tools.",
        bigdataheader3:
          "The general purpose of big data solutions is to provide insights about data by analyzing and reporting using modeling and visualization technologies in Microsoft Power BI or Microsoft Excel. Analysis and reporting also includes interactive data reviews by data experts or data analysts.",
        bigdataarchitecture: "Big Data Architecture",
        bigdataarchitecturedesc1:
          "Operations such as collection and storage of data sources, instant message retrieval, analysis and reporting are performed by big data architects. While collecting data, it is necessary to be conscious of how it will contribute to the database and what it will bring to the business.",
        bigdataarchitecturedesc2:
          "During the design phase, high-level security layers and system recovery plans are created in case of extraordinary failures.",
        embeddedsystemdesign: "Embedded System Design",
        embeddedheader:
          "Embedded system design is integrated systems made to perform a special function. They work in real time using specific resources. It consists of two subcomponents, hardware and software.",
        embeddeddescription:
          "Embedded systems are controlled by microcontrollers and microprocessors. The microcontroller works like a brain to control the system. It enables the developed software to be stored and processed. A microprocessor is a device that contains a CPU and provides mechanism control. Data processing and flow is provided by the CPU. It also allows multiple operations to be performed at the same time.",
        embeddeddescription2:
          "PCB design in our embedded system design applications belongs to CEVHER Digital Solutions. We can respond very flexibly to company demands, and we can produce solutions for your software requierements in this direction.",
        smartfactoryapplications: "Smart Factory Applications",
        sfaheader:
          "The smart grid is based on the principle of managing energy generation and consumption infrastructure from a single center. Smart grids process the data, interpret it and manage the needs according to the data analysis it receives.",
        sfadescription1:
          "Smart grid infrastructure ensures coordination and data flow across grid assets. Energy management and distribution, better control, efficiency, security, and cost reduction are among the important benefits of smart grids.",
        sfadescription2:
          "All the details encountered during the production and production phase in the facilities can be tested through the software. Thanks to the smart factory system, the continuity of the productive structure of the factory is ensured by predetermining what steps should be taken for maintenance and repair and at what intervals.",
        sfadescription3:
          "Intelligent distribution has a balancing and optimizing structure. Thanks to its automatic monitoring and analysis feature, it has an important place in smart grids with its structure that can predict failures based on weather conditions and power outage history.",
        smartgridsystems: "Smart Grid Systems",
        sgsheader:
          "The smart grid is based on the principle of managing energy generation and consumption infrastructure from a single center. Smart grids process data, interpret it and manage the needs according to the data analysis it receives.",
        sgsdescription1:
          "Smart grid infrastructure ensures coordination and data flow across grid assets. Energy management and distribution, better control, efficiency, security, and cost reduction are among the important benefits of smart grids.",
        sgsdescription2:
          "Smart distribution has a balancing and optimizing structure. Thanks to its automatic monitoring and analysis feature, it has an important place in smart grids with its structure that can predict failures based on weather conditions and power outage history.",
        plcautomations: "PLC Automations",
        plcheader:
          "As Cevher Digital Solutions, we are able to respond flexibly to demands from all kinds of production facilities, processes and machinery application areas.",
        plcdescription1:
          'PLC is the abbreviation of Programmable Logic Controller, which means "programmable logical controller". Developed for industrial applications, it can process digital and analogue data used in the production departments of factories or in the control processes of working machines, contains many counter commands, can be programmed according to the process, and has the ability to perform automatic work. It is a microprocessor based device.',
        plcdescription2:
          "The biggest advantages compared to computers and microprocessors are that they are industrial, they are specially designed to be resistant to shocks, electrical noises and temperature differences. When there are communication requirements, PLCs can also communicate with each other, with industrial computers or other smart devices.",
        plcdescription3: "Communication protocols that we commonly use;",
        plctable1: "•	Profinet",
        plctable2: "•	Profibus",
        plctable3: "•	Anybus ",
        plctable4: "• Modbus ",
        plctable5: "• TCP/IP ",
        career: "Career",
        join: "Join the Cevher Digital Solutions",
        name: "Name",
        mail: "Mail",
        resume: "Upload Resume",
        message: "Your Message",
        submit: "Submit",
        delivered: "Your resume has been successfully delivered to us!",
        solutions: "Solutions",
      },
    },
    tr: {
      translation: {
        cds: "Cevher Dijital Çözümler A.Ş.",
        lang: "tr",
        oplang: "en",
        comingsoon: "Yakında",
        reachus: "Öğrenmek için bize ulaşın",
        aboutus: "Hakkımızda",
        contactus: "Ürünlerimizi keşfetmek için bizimle iletişime geçin",
        showmore: "Daha Fazla",
        aboutdescription: "Geleceği Araştırır ve Geliştiririz.",
        address:
          "Dokuz Eylül Üniversitesi Tınaztepe Yerleşkesi Doğuş Caddesi No:207/AG DEPARK Tınaztepe Beta Binası, Beşinci Kat, Ofis, 503 Buca-İZMİR",
        phone: "Tel : ",
        headlineWhere: "",
        headline:
          "Tasarım endüstriyle buluşuyor, Bugün geliştirildi, Yarın tasarlandı...",
        subline: "Fark yaratmak için.",
        home: "Ana Sayfa",
        products: "Ürünler",
        opportunities: "Fırsatlar",
        consultancy: "Danışmanlık",
        about: "Hakkımızda",
        contact: "İletişim",
        datagovernance: "Veri Yönetimi",
        dgheader:
          "Veri analiz modellerini etkili ve doğru oluşturarak firmalara büyüme, satış, yatırım gibi birçok kritik süreçte destek sağlanmaktadır. Firmalara yapılan analizler doğrultusunda doğru stratejiler oluşturmanıza destek oluyoruz.",
        dataanalytics: "Veri analizi",
        dataanalyticsdesc1:
          "İleri odaklı doğru kararlar almak söz konusu olduğunda veri yönetimi ve veri analizi büyük önem arz etmektedir. Veri yönetimiyle, kuruma en yüksek düzeyde fayda sağlayacak kararın seçimi olanağı sağlanmaktadır.",
        dataanalyticsdesc2:
          "Veri Analizi, ham verinin toplanması, ayıklanması ve işlenmesi adımlarından sonra kritik kararlar alma süreçlerinde bilimsel ve etkin bir araçtır.",
        dataanalyticsdesc3:
          "Veriye ulaşma, veri analizi, veri görselleştirme adımlarından oluşan veri analiz süreci, her adımda farklı program kullanımı gerektirmektedir. Veri analiz uzmanlarımız tarafından Veri analiz programları arasından karar verilen ihtiyaca uygun olan programlar seçerek verilerinizi analiz ediyoruz. ",
        datawarehouse: "Veri Deposu",
        datawarehousedesc1:
          "Veri deposu, veri depolama için belirlenmiş  hedefi temsil eden bir veri koleksiyonudur. Bu depolama birimi bilgiye erişmeyi mümkün kılar.",
        businessanalytics: "İş Analizi",
        baheader:
          "İş analizi, bir işin küçük parçalara ayrılarak incelenmesi süreci sonrası ihtiyaçların belirlenmesi hususunda mevcut sorunlara çözüm arayan bir araştırma disiplinidir.",
        datavisualization: "Veri görselleştirme",
        datavisualizationdesc1:
          "Veri görselleştirme için veri analizi aracı tasarımı ve makine öğrenmesi büyük bir önem taşır. İnsan beyni görsel bilgileri çok daha hızlı işler. Bu noktada belirlenen araç ile büyük verilerin çeşitli grafikler ile daha anlaşılır hale getirilerek analiz edilmesi kolaylaştırılabilir.",
        dataanalytictooldesign: "Veri Analizi Aracı Tasarımı",
        dataanalytictooldesigndesc1:
          "Veri analizi aracı tasarımı, görsel, sayısal ve sözel olmak üzere üç başlıkta toplanabilir. Görsel araçlar grafik ve harita yardımıyla analiz sonucu çıkarırken sayısal araçlar sayılar ile bir sonuç ortaya koyar. Sözel analiz araçları, yöntem ve problemlerin belirlenmesi için sonuçlar ortaya koyar.",
        machinelearning: "Makine Öğrenmesi",
        machinelearningdesc1:
          "Makine öğrenmesinde, matematiksel modellerin işlenerek aynı bir insan zekasını taklit edercesine sistem oluşturulur. Böylelikle ileriye dönük yanıtların verilmesinde daha kesin sonuçlar elde edilmesi amaçlanır.",
        tailormadesoftwaresolutions: "Özel Yazılım Çözümleri",
        tmheader:
          "İstek ve ihtiyaçlarınız doğrultusunda yetenekli yazılım ekibimiz ile özel çözümler üretip karşılaştığınız sorunları çözebiliriz.",
        tmheader2:
          "Süreç, ihtiyaç analizi ile başlayıp taslak onayı ardından tasarım ve geliştirme aşamalarıyla devam eder.",
        tmsubtitle: "Masaüstü Çözümleri, Mobil Çözümleri, Web Çözümleri",
        tmsubtitledesc1:
          "Belirli karar verme aşamalarından geçtikten sonra yüksek performansa sahip yazılım çözümleri, masaüstü, mobil ve web tabanlı olarak geliştirilebilir.",
        tmsubtitledesc2:
          "İstek ve ihtiyaçlarınız doğrultusunda yetenekli yazılım ekibimiz ile özel çözümler üretip karşılaştığınız sorunları çözebiliriz. Bu süreç, ihtiyaç analizi ile başlayıp taslak onayı ardından tasarım ve geliştirme aşamalarıyla devam eder.",
        industrialprojectapplications: "Endüstriyel Proje Uygulamaları",
        iotbasedsystems: "IoT Temelli Sistemler",
        industrialdescription1:
          "Nesnelerin interneti veya IOT, birbiriyle ilişkili bilgi işlem cihazları, mekanik ve dijital makineler kısacası günlük hayatta kullanılan nesnelerin tümünün internet sayesinde diğer nesneler ile veri alışverişi yapabilmesini ve nesnelerin birbiriyle tam olarak senkronizasyon halinde olmalarını sağlayan teknolojidir.",
        industrialdescription2:
          "IOT cihazları, topladıkları sensör verileri, verileri yerel olarak analiz etmek üzere buluta göndererek veya yerel olarak analiz edildiği buluttan başka bir cihaza bağlayarak paylaşır. Bazı durumlarda bu cihazlar diğer cihazlarla iletişim kurabilir ve birbirlerinden aldıkları bilgiler doğrultusunda hareket edebilmektedir. Cihazlar işlerin çoğunu insan müdahalesi olmadan yapar, ancak cihazları ayarlamak, talimat vermek veya verilere erişmek gibi durumlarda cihazlarla etkileşimde bulunabilmektedir.",
        spleconsultancy: "SPLE Danışmanlığı",
        domainanalysis: "Etki Alanı Analizi",
        featureextraction: "Özellik Çıkarma",
        spledescription:
          "Etki alanı analizi, bir ürün ailesinin ürünleri arasındaki ortak ve değişken özellikleri belirlemek için yazılım ürün hattı mühendisliğinde (SPLE) önemli bir faaliyettir.",
        spledescription2:
          "Özellik modelleri yaygın olarak tanımlanan ortak noktaları ve varyasyonları göstermek için kullanılır. Ancak, alan analizi yapmak ve bir özellik modeli oluşturmak için önemli bir zaman ve çaba gerektirir.",
        bigdata: "Büyük Veri ve Veri Toplama",
        bigdataheader:
          "Büyük veri kaynaklarına, ilişkisel veritabanları gibi uygulama verisi depoları, uygulamalar tarafından üretilen web sunucusu günlük dosyaları gibi statik dosyalar, IoT cihazları gibi gerçek zamanlı veri kaynakları örnek verilebilir.",
        bigdataheader2:
          "Büyük veri çözümleri için veriler analiz için hazırlanır sonrasında işlenen veriler analiz araçları kullanılarak sorgulanabilecek yapılandırılmış bir hale getirilir.",
        bigdataheader3:
          "Büyük veri çözümlerinin genel amacı Microsoft Power BI veya Microsoft Excel'deki modelleme ve görselleştirme teknolojileri kullanılarak analiz ve raporlama yapılarak veriler hakkında öngörüler sağlamaktır. Analiz ve raporlama, veri uzmanları veya veri analistlerinin etkileşimli veri incelemelerini de kapsamaktadır.",
        bigdataarchitecture: "Büyük Veri Mimarisi",
        bigdataarchitecturedesc1:
          "Veri kaynaklarının toplanması, depolanması, anlık ileti alımı, analiz ve raporlama gibi işlemler büyük veri mimarları tarafından gerçekleştirilir. Veri toplanırken veri tabanına nasıl katkı sağlayacağı, sağladığı katkının işletmeye ne kazandıracağı konusunda bilinçli olmak gerekmektedir.",
        bigdataarchitecturedesc2:
          "Tasarım aşamasında üst düzey güvenlik katmanları ve olağanüstü arızalarda sistem kurtarma planları oluşturulmaktadır.",
        embeddedsystemdesign: "Gömülü Sistem Tasarımı",
        embeddedheader:
          "Gömülü sistem, özel bir işlevi gerçekleştirmek adına yapılan entegre sistemlerdir. Belirli kaynaklar kullanılarak gerçek zamanlı olarak çalışırlar. Donanım ve yazılım olmak üzere iki alt bileşenden oluşur.",
        embeddeddescription:
          "Gömülü sistemler mikrodenetleyiciler ve mikroişlemciler tarafından kontrol edilir. Mikrodenetleyici, sistem kontrolünü sağlamak amacıyla bir beyin gibi çalışır. Geliştirilen yazılımı depolayıp işlenecek hale gelmesini sağlar. Mikroişlemci, yapısında CPU bulunduran ve mekanizma kontrolü sağlayan araçtır. CPU ile veri işleme ve akışı sağlanır. Ayrıca, aynı anda birçok işlem yapılmasını sağlar.",
        embeddeddescription2:
          "Gömülü sistem tasarımı uygulamalarımızda PCB tasarımı CEVHER Digital Solutions’a aittir. Firma taleplerine oldukça esnek yanıt verebilir, yazılım ihtiyaçlarınıza da bu doğrultuda çözümler üretebiliriz.",
        embeddedtable1: "• GSM üzerinden IoT Tabanlı Tabela Kontrolü",
        embeddedtable2: "• Otomatik Tarımsal Sulama ve İlaçlama Sistemi",
        embeddedtable3: "• Sıvı Seviye Kontrol Sistemi",
        embeddedtable4: "• Elektronik Ekolayzır Sistemi(Bluetooth)",
        smartfactoryapplications: "Akıllı Fabrika Uygulamaları",
        sfaheader:
          "Akıllı Fabrikalar; makine ve ekipmanların otomasyonuyla kendi kendine süreçleri iyileştirebildiği, dijitalleştirilmiş ortamlardır. Bu ortamlar üretimde verimliliğin artmasını, üretimin anlık olarak takip edilmesini ve uzaktan müdahale edilmesini sağlayan otomasyon sistemlerini içermektedir.",
        sfadescription1:
          "En büyük yenilikler arasında fabrikaya dair tüm gelişmelerin ve verilerin dijital ortama yani bilgisayara aktarılması gereklidir. Her bir fabrika için farklı yazılımlar kullanılarak fabrikaya dair tüm veriler bu ortama aktarılır.",
        sfadescription2:
          "Tesislerde yapılan üretim ve üretim aşaması sürecinde karşılaşılan tüm detaylar yazılım aracılığı ile test edilebilir durumdadır. Akıllı fabrika sistemi sayesinde bakım ve onarım için ne gibi adımlar atılması gerektiği, hangi aralıklarda yapılacağı önceden belirlenerek fabrikanın üretken yapısında da süreklilik sağlanmaktadır.",
        sfadescription3:
          "Üretim otomasyonu sistemleri ile donatılan akıllı fabrikalar sayesinde anlık veri analizi yapılabilmektedir. Böylece fabrikanın getirisinin ne düzeyde olduğu rahatlıkla tespit edilebilir duruma gelmektedir.",
        smartgridsystems: "Akıllı Şebeke Sistemleri",
        sgsheader:
          "Akıllı şebeke, enerji üretim ve tüketim altyapısının tek merkezden yönetilmesi ilkesine dayanmaktadır. Akıllı şebekeler verileri işler, yorumlar ve aldığı veri analizlerine göre ihtiyaçları yönetir.",
        sgsdescription1:
          "Akıllı şebeke altyapısı, şebeke varlıkları boyunca koordinasyon ve veri akışını sağlar. Enerji yönetimi ve dağıtımı, daha iyi kontrol, verimlilik, güvenlik, maliyetlerin düşürülmesi akıllı şebekelerin sağladığı önemli faydalardandır.",
        sgsdescription2:
          "Akıllı dağıtım, dengeleyici ve optimize edici bir yapıya sahiptir. Otomatik izleme ve analiz etme özelliği sayesinde hava durumu ve enerjisiz kalma geçmişine bağlı olarak arızaları tahmin edebilen yapısıyla akıllı şebekelerde önemli bir yere sahiptir.",
        plcautomations: "PLC Otomasyonu",
        plcheader:
          "Cevher Digital Solutions olarak her türlü üretim tesisi, süreç ve makine uygulama alanlarından gelen taleplere esnek bir şekilde cevap verebilmekteyiz.",
        plcdescription1:
          'PLC, "programlanabilir mantıksal denetleyici” anlamına gelen Programmable Logic Controller ifadesinin kısaltmasıdır. Endüstriyel uygulamalar için geliştirilmiş, fabrikaların üretim bölümlerinde veya çalışan makinaların kontrol işlemlerinde kullanılan dijital ve analog verileri işleyebilen, içerisinde birçok sayıcı komutlar barındıran, sürece göre programlanabilen, otomatik iş yaptırma kabiliyeti olan mikroişlemci tabanlı bir cihazdır.',
        plcdescription2:
          "Bilgisayarlara ve mikroişlemcilere kıyasla en büyük avantajları endüstriyel olmaları, darbelere, elektriksel gürültülere, sıcaklık farklarına dayanıklı olarak özel tasarlanmış olmalarıdır. Haberleşme gereksinimleri olduğunda PLC’ler kendi arasında, endüstriyel bilgisayarlarla veya diğer akıllı cihazlarla da iletişim kurabilmektedir.",
        plcdescription3: "Yaygın olarak kullandığımız haberleşme protokolleri;",
        plctable1: "•	Profinet",
        plctable2: "•	Profibus",
        plctable3: "•	Anybus ",
        plctable4: "• Modbus ",
        plctable5: "• TCP/IP ",
        career: "Kariyer",
        join: "Cevher Dijital Çözümler Ekibine Katılın",
        name: "İsim",
        mail: "Mail",
        resume: "Özgeçmiş Yükleyin",
        message: "Mesajınız",
        submit: "Gönder",
        delivered: "Özgeçmişiniz başarılı şekilde bize ulaştı!",
        solutions: "Çözümlerimiz",
      },
    },
  },
  lng: "en",
  fallbackLng: "en",
});

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
