import { useTranslation } from "react-i18next";
import React from "react";
import { GetWorkingAreasData } from "../../constants/WorkingAreas/WorkingAreas";
import { Navbar } from "../../components/Navbar/Navbar";

export const Consultancy = () => {
  const { t } = useTranslation();
  const data = GetWorkingAreasData(t)[4];

  return (
    <div>
      <Navbar />
      <div className="w-screen md:h-screen h-full relative z-10 flex md:flex-row flex-col md:px-60 px-3 items-center justify-center font-[RalewayRegular] text-gray-100">
        <div className="md:mr-24 md:max-w-2xl">
          <p className="text-3xl font-bold">{data.title}</p>
          {data.subtitles.map((subtitle) => (
            <p className="md:text-2xl text-xl font-bold">- {subtitle}</p>
          ))}
          {data.descriptions.map((description) => (
            <p className="md:text-xl text-l">{description}</p>
          ))}
        </div>
        <div className="flex flex-col md:ml-24 items-center">
          {data.images.map((img) => (
            <img src={img} className="md:w-96 w-11/12 py-3" alt={img} />
          ))}
        </div>
      </div>
    </div>
  );
};
