import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { stagger, fadeInUp } from "../../components/Animations/Animations";
import GoogleMapReact from "google-map-react";
import { Navbar } from "../../components/Navbar/Navbar";

export const Contact = () => {
  const { t } = useTranslation();
  const location = { lat: 38.3664470191259, lng: 27.207517314435467 };

  const renderMarker = (map, maps) => {
    let marker = new maps.Marker({
      position: location,
      map,
      title: "CDS",
    });
    return marker;
  };

  return (
    <div>
      <Navbar />
      <motion.div
        initial="initial"
        animate="animate"
        variants={stagger}
        className="w-screen h-screen flex md:flex-row flex-col md:pl-12 md:pr-12 md:pt-12 pl-4 pr-4 relative z-10 justify-between text-gray-100 font-[RalewayRegular]"
      >
        <div className="md:w-2/3 flex flex-col ">
          <motion.p variants={fadeInUp} className="text-3xl font-bold">
            {t("cds")}
          </motion.p>
          <motion.p variants={fadeInUp} className="text-xl">
            {t("address")}
          </motion.p>
          <motion.p variants={fadeInUp} className="text-xl mt-12">
            {t("phone")} +90 (232) 298 20 00
          </motion.p>
          <motion.p variants={fadeInUp} className="text-xl">
            Mail : info@cevherwheels.com
          </motion.p>
        </div>
        <motion.div
          variants={fadeInUp}
          className="md:w-2/3 md:h-1/2 h-full md:mb-0 md:mt-0 mb-4 mt-4"
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBh0HCRB3csoLLRIwWNGJtPtys3v5n2g0M",
            }}
            defaultCenter={location}
            defaultZoom={15}
            onGoogleApiLoaded={({ map, maps }) => renderMarker(map, maps)}
          ></GoogleMapReact>
        </motion.div>
      </motion.div>
    </div>
  );
};
