import React, { useState } from "react";
import { IoArrowDown } from "react-icons/io5";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import { GetWorkingAreasData } from "../../constants/WorkingAreas/WorkingAreas";
import { fadeInUp, stagger } from "../../components/Animations/Animations";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";
import { Navbar } from "../../components/Navbar/Navbar";

const Home = () => {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      <Navbar />
      <div className="flex flex-col w-screen relative z-10 md:pt-16 md:pb-16 pt-8 pb-8">
        <motion.div
          className="w-screen flex flex-col items-center"
          variants={stagger}
          initial="initial"
          animate="animate"
          whileInView={fadeInUp}
          viewport={{ once: true }}
        >
          <div className="lg:w-1/2 pl-6 pr-6">
            <motion.p
              variants={fadeInUp}
              className="md:text-5xl text-3xl text-gray-100 text-center leading-relaxed font-bold font-[RalewayRegular]"
            >
              <span className="text-5xl">
                {t("headlineWhere")} <br />{" "}
              </span>
              {t("headline")}
            </motion.p>
            <motion.p
              variants={fadeInUp}
              className="text-2xl text-gray-100 text-center mt-12 font-[RalewayRegular]"
            >
              {t("subline")}
            </motion.p>
          </div>
          <motion.button
            onClick={() => {
              const element = document.querySelector(".bottomContainer");
              element.scrollIntoView({ behavior: "smooth" });
            }}
            variants={fadeInUp}
            className="flex w-16 h-16 border-2 border-blue-400 mt-24 rounded-full justify-center items-center"
          >
            <IoArrowDown className="w-8 h-8" color="#00AEEF" />
          </motion.button>
        </motion.div>
        <div className="bottomContainer carousel-container w-screen justify-center flex items-center font-[RalewayRegular]">
          <Carousel activeIndex={index} onSelect={handleSelect}>
            {GetWorkingAreasData(t).map((item) => {
              return (
                <Carousel.Item key={item.title} className="h-full pb-12">
                  <div className="md:flex md:flex-row md:justify-center flex flex-col w-screen ">
                    <div className="carousel-img-container">
                      <img
                        src={item.images[0]}
                        className="carousel-img md:w-96 md:h-96 w-32 h-32 mb-6 md:mb-24 self-center"
                        alt={item.title}
                      />
                    </div>
                    <div className="flex flex-col md:w-1/2 h-full ml-3 md:pl-3 mb:pr-3 pl-12 pr-12">
                      <p className="md:text-4xl text-2xl text-gray-100 font-bold">
                        {item.title}
                      </p>
                      {item.subtitles.map((subtitle) => (
                        <p
                          className="hidden md:flex text-xl font-bold text-gray-100"
                          key={subtitle.title}
                        >
                          - {subtitle.title}
                        </p>
                      ))}
                      <p className="text-xl text-gray-100 text-ellipsis mb-4">
                        {item.header
                          ? item.header
                          : item.subtitles[0].descriptions[0]}
                      </p>
                      <div className="self-center mb:mt-0">
                        <motion.button
                          initial={{ opacity: 0, y: -10 }}
                          whileInView={{
                            opacity: 1,
                            y: 0,
                            transition: {
                              duration: 1,
                              stiffness: 100,
                            },
                          }}
                          className="w-fit h-fit p-2 mt-4 bg-[#00AEEF] rounded-lg self-center"
                        >
                          <Link to={item.link} className="no-underline ">
                            <p className="text-xl text-blue-900 font-bold m-auto">
                              {t("showmore")}
                            </p>
                          </Link>
                        </motion.button>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Home;
