import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./Footer.styles.css";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <Link to="/contact" className="no-underline">
        <p className="contact-text">{t("contactus")}</p>
      </Link>{" "}
      <div>
        <p className="cds-text">Cevher Digital Solutions © 2022</p>
        {
          // TODO : Link iconları için ayrılmıştır...
          /* <div>
          <a
            className="justify-self-end"
            href="https://www.linkedin.com/company/cevher-digital-solutions"
          >
            <AiFillLinkedin className="w-8 h-8 text-gray-100 " />
          </a>
        </div> */
        }
      </div>
    </div>
  );
};
