import "./App.css";
import Home from "./Pages/Home/Home";
import ParticleBackground from "./components/ParticlesBackground/ParticlesBackground";
import { Navbar } from "./components/Navbar/Navbar";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { Footer } from "./components/Footer/Footer";
import { Products } from "./Pages/Products/Products";
import { Opportunities } from "./Pages/Opportunities/Opportunities";
import { Consultancy } from "./Pages/Consultancy/Consultancy";
import { About } from "./Pages/About/About";
import { Contact } from "./Pages/Contact/Contact";
import { useEffect } from "react";
import { DataGovernance } from "./Pages/DataGovernance/DataGovernance";
import { BusinessAnalytics } from "./Pages/BusinessAnalytics/BusinessAnalytics";
import { TailormadeSoftware } from "./Pages/TailormadeSoftware/TailormadeSoftware";
import { IndustrialProjectApp } from "./Pages/IndustrialProject/IndustrialProjectApp";
import { BigData } from "./Pages/BigData/BigData";
import { EmbeddedSystem } from "./Pages/EmbeddedSystem/EmbeddedSystem";
import { Career } from "./Pages/Career/Career";
import { Success } from "./Pages/Career/Success/Success";
import { PLCAutomations } from "./Pages/PLC";
import SmartFactoryApplications from "./Pages/SmartFactoryApplications";
import SmartGridSystems from "./Pages/SmartGridSystems";

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="bg-gray-800">
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/products" element={<Products />} />
        <Route path="/opportunities" element={<Opportunities />} />
        <Route path="/about" element={<About />} />
        <Route path="/spleconsultancy" element={<Consultancy />} /> */}
        <Route path="/career" element={<Career />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/datagovernance" element={<DataGovernance />} />
        <Route path="/businessanalytics" element={<BusinessAnalytics />} />
        <Route
          path="/tailormadesoftwaresolutions"
          element={<TailormadeSoftware />}
        />
        <Route
          path="/industrialprojectapplications"
          element={<IndustrialProjectApp />}
        />
        <Route path="/spleconsultancy" element={<Consultancy />} />
        <Route path="/bigdata" element={<BigData />} />
        <Route path="/embeddedsystemdesign" element={<EmbeddedSystem />} />
        <Route
          path="/smartfactoryapplications"
          element={<SmartFactoryApplications />}
        />
        <Route path="/smartgridsystems" element={<SmartGridSystems />} />
        <Route path="/plcautomations" element={<PLCAutomations />} />
        <Route
          path="*"
          element={<Navigate to="/" />}
          component={<div>404</div>}
        />
        <Route path="/career/success" element={<Success />} />
      </Routes>
      <Footer />
      <ParticleBackground />
    </div>
  );
}

export default App;
