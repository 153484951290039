import React from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../components/Navbar/Navbar";

import "./Career.styles.css";

export const Career = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Navbar />
      <div className="full-body">
        <div className="container">
          <h2 className="header">{t("join")}</h2>
          <form
            className="all-inputs"
            action="https://formsubmit.co/info@cevherdigital.com"
            method="post"
            encType="multipart/form-data"
          >
            <input type="hidden" name="_captcha" value="false" />
            <input
              type="hidden"
              name="_subject"
              value="CDS Career Submission"
            />
            <input
              type="hidden"
              name="_next"
              value="https://www.cevherdigital.com/#/career/success"
            />
            <div className="input-container">
              <h6 className="title">{t("name")} *</h6>
              <input name="name" type="text" className="input" required />
            </div>
            <div className="inputs">
              <div className="input-container">
                <h6 className="title">{t("mail")} *</h6>
                <input name="email" type="email" className="input" required />
              </div>
              <div className="input-container">
                <h6 className="title">{t("phone")} *</h6>
                <input name="number" type="number" className="input" required />
              </div>
            </div>
            <div className="input-container">
              <h6 className="title">{t("resume")} *</h6>
              <input
                name="attachment"
                type="file"
                accept="application/pdf"
                className="resume-container"
                required
              />
            </div>
            <div className="input-container">
              <h6 className="title">{t("message")} *</h6>
              <textarea
                name="message"
                className="input message-input"
                required
              />
            </div>
            <input type="submit" className="button" value={t("submit")} />
          </form>
        </div>
      </div>
    </div>
  );
};
