export function GetWorkingAreasData(t) {
  return [
    {
      title: t("datagovernance"),
      link: "/datagovernance",
      subtitles: [
        {
          title: t("dataanalytics"),
          descriptions: [
            t("dataanalyticsdesc1"),
            t("dataanalyticsdesc2"),
            t("dataanalyticsdesc3"),
          ],
        },
        {
          title: t("datawarehouse"),
          descriptions: [t("datawarehousedesc1")],
        },
      ],
      images: [
        require("../../assets/images/dg/DataGovernance1.jpg"),
        require("../../assets/images/dg/DataGovernance2.jpg"),
      ],
      header: t("dgheader"),
    },
    {
      title: t("businessanalytics"),
      link: "/businessanalytics",
      subtitles: [
        {
          title: t("datavisualization"),
          descriptions: [t("datavisualizationdesc1")],
        },
        {
          title: t("dataanalytictooldesign"),
          descriptions: [t("dataanalytictooldesigndesc1")],
        },
        {
          title: t("machinelearning"),
          descriptions: [t("machinelearningdesc1")],
        },
      ],
      images: [
        require("../../assets/images/ba/BusinessAnalytics.jpg"),
        require("../../assets/images/ba/dataanalaytictooldesign.png"),
        require("../../assets/images/ba/MachineLearning.jpg"),
      ],
      header: t("baheader"),
    },
    {
      title: t("tailormadesoftwaresolutions"),
      link: "/tailormadesoftwaresolutions",
      subtitles: [
        {
          title: t("tmsubtitle"),
          descriptions: [t("tmsubtitledesc1"), t("tmsubtitledesc2")],
        },
      ],
      images: [
        require("../../assets/images/tm/TailorMadeSoftwareSolutions.jpg"),
        require("../../assets/images/tm/tailoredsoftware.png"),
      ],
      header: [t("tmheader"), t("tmheader2")],
    },
    {
      title: t("industrialprojectapplications"),
      link: "/industrialprojectapplications",
      subtitles: [
        {
          title: t("iotbasedsystems"),
          descriptions: [
            t("industrialdescription1"),
            t("industrialdescription2"),
          ],
        },
      ],
      images: [
        require("../../assets/images/ipa/iot.jpg"),
        require("../../assets/images/ipa/IoTBasedSystems1.jpg"),
        require("../../assets/images/ipa/IoTBasedSystems2.jpg"),
      ],
    },
    /* {
      title: t("spleconsultancy"),
      link: "/spleconsultancy",
      subtitles: [t("domainanalysis"), t("featureextraction")],
      images: [
        require("../../assets/images/spleconsultancy.png"),
        require("../../assets/images/spleconsultancy2.png"),
      ],
      descriptions: [t("spledescription"), t("spledescription2")],
      tables: [],
    }, */
    {
      title: t("bigdata"),
      link: "/bigdata",
      subtitles: [
        {
          title: t("bigdataarchitecture"),
          descriptions: [
            t("bigdataarchitecturedesc1"),
            t("bigdataarchitecturedesc2"),
          ],
        },
      ],
      images: [
        require("../../assets/images/bd/bigdatahome.jpg"),
        require("../../assets/images/bd/DataWarehouse.jpg"),
      ],
      header: [t("bigdataheader"), t("bigdataheader2")],
    },
    {
      title: t("embeddedsystemdesign"),
      link: "/embeddedsystemdesign",
      subtitles: [
        {
          title: "",
          descriptions: [t("embeddeddescription"), t("embeddeddescription2")],
        },
      ],
      images: [require("../../assets/images/esd/EmbeddedSystemDesign.jpg")],
      header: t("embeddedheader"),
    },
    {
      title: t("smartfactoryapplications"),
      link: "/smartfactoryapplications",
      subtitles: [
        {
          title: "",
          descriptions: [
            t("sfadescription1"),
            t("sfadescription2"),
            t("sfadescription3"),
          ],
        },
      ],
      images: [
        require("../../assets/images/sfa/SmartFactoryApplications1.jpg"),
        require("../../assets/images/sfa/SmartFactoryApplications2.jpg"),
      ],
      header: t("sfaheader"),
    },
    {
      title: t("smartgridsystems"),
      link: "/smartgridsystems",
      subtitles: [
        {
          title: "",
          descriptions: [t("sgsdescription1"), t("sgsdescription2")],
        },
      ],
      images: [require("../../assets/images/sgs/SmartGridSystems.jpg")],
      header: t("sgsheader"),
    },
    {
      title: t("plcautomations"),
      link: "/plcautomations",
      subtitles: [
        {
          title: "",
          descriptions: [
            t("plcdescription1"),
            t("plcdescription2"),
            t("plcdescription3"),
          ],
        },
      ],
      images: [require("../../assets/images/plc/PLCAutomations.jpg")],
      header: t("plcheader"),
    },
  ];
}
