import React from "react";
import Particles from "react-tsparticles";
import ParticleConfig from "./particle-config";

const Particle = () => {
  return (
    <Particles
      id="tsparticles"
      params={ParticleConfig}
    ></Particles>
  );
};

export default Particle;
