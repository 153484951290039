import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import { GetWorkingAreasData } from "../../constants/WorkingAreas/WorkingAreas";
import CDSLogo from "../../assets/images/cds_logo.png";
import "./Navbar.styles.css";

export const Navbar = () => {
  const { t } = useTranslation();
  const [isOpen, toggleOpen] = useState(false);

  useEffect(() => {
    toggleOpen(false);
  }, []);

  return (
    <nav className="navbar">
      <a href="/" className="logo">
        <img src={CDSLogo} alt="CDS Logo" className="logo-img" />
      </a>
      <div className="menu-icon" onClick={() => toggleOpen(!isOpen)}>
        <FiMenu />
      </div>
      <div className={isOpen ? "menu active" : "menu"}>
        <div className="menu-list">
          <Link to="/">{t("home")}</Link>
          <div className="menu-products">
            <div className="products">{t("solutions")}</div>
            <div className="dropdown-container">
              <ul className="dropdown-list">
                {GetWorkingAreasData(t).map((item) => (
                  <li className="dropdown-list-item" key={item.link}>
                    <Link to={item.link} replace>
                      {item.title}
                    </Link>
                    <hr />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Link to="/career">{t("career")}</Link>
          <Link to="/contact">{t('contact')}</Link>
          <div className="vr" />
          <div
            className="language-btn"
            onClick={() => i18n.changeLanguage(t("oplang"))}
          >
            {t("lang").toUpperCase()}
          </div>
        </div>
      </div>
    </nav>
  );
};
