import React from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../components/Navbar/Navbar";
import { GetWorkingAreasData } from "../../constants/WorkingAreas/WorkingAreas";

export const PLCAutomations = () => {
  const { t } = useTranslation();
  const data = GetWorkingAreasData(t)[8];
  return (
    <div>
      <Navbar />
      <div className="w-screen h-full relative z-10 flex flex-col pb-4 text-gray-100">
        <div className="sub-header-container plc-background">
          <div className="sub-header-text-wrapper">
            <div className="text-6xl font-bold md:text-left">{data.title}</div>
            <div className="text-xl pt-4">{data.header}</div>
          </div>
        </div>
        {data.subtitles.map((subtitle, index) => (
          <div
            className={`ba-card flex py-8 md:px-12 items-center md:max-w-7xl md:self-center ${
              index % 2 === 0 ? "" : "flex-row-reverse"
            }`}
          >
            <div className="ba-card-desc pl-4 pr-8">
              <div className="text-3xl font-semibold">{subtitle.title}</div>
              {subtitle.descriptions.map((desc) => (
                <div className="mt-2">{desc}</div>
              ))}
            </div>
            <img
              src={data.images[index]}
              className="ba-card-image"
              alt="Logo"
            ></img>
          </div>
        ))}
      </div>
    </div>
  );
};
